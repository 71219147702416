import useLinkPageContent from '../hooks/page/LinksPageHook';
import MetaHelmet from '../components/MetaHelmet';
import Links from '../components/Links';

const LinksPage = () => {
  const linksPageData = useLinkPageContent();

  if (linksPageData.error.hasOccured) {
    return <p>Przepraszamy, nie możemy teraz wyświetlić tej strony.</p>;
  }

  if (linksPageData.isLoading) {
    return '';
  }

  return (
    <>
      <MetaHelmet {...linksPageData} />
      <Links {...linksPageData.reports} />
      <Links {...linksPageData.research} />
      <Links {...linksPageData.press} />
    </>
  );
};

export default LinksPage;
