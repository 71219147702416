import { Link } from 'react-router-dom';

const Button = ({ className, to, text, isExternal = false }) => {
  if (!to || !text) return;
  if (isExternal) {
    return (
      <a
        href={`${to}`}
        className={
          className
            ? `${className} bbng_button d-inline-flex justify-content-center align-items-center`
            : 'bbng_button d-inline-flex justify-content-center align-items-center'
        }
      >
        <span>{text}</span>
      </a>
    );
  }
  return (
    <Link
      to={`${to}`}
      className={
        className
          ? `${className} bbng_button d-inline-flex justify-content-center align-items-center`
          : 'bbng_button d-inline-flex justify-content-center align-items-center'
      }
    >
      <span>{text}</span>
    </Link>
  );
};

export default Button;
