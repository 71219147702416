import useHomePageContent from '../hooks/page/HomePageHook';
import MainPageHeader from '../components/MainPageHeader';
import AboutUsHome from '../components/AboutUsHome';
import VisionList from '../components/VisionList';
import Quote from '../components/Quote';
import ArticlesHome from '../components/ArticlesHome';
import MetaHelmet from '../components/MetaHelmet';

const HomePage = () => {
  const homePageData = useHomePageContent();

  if (homePageData.error.hasOcurred) {
    return <p>Przepraszamy, nie możemy teraz wyświetlić tej strony.</p>;
  }

  if (homePageData.isLoading) {
    return '';
  }

  return (
    <>
      <MainPageHeader {...homePageData} />
      <AboutUsHome {...homePageData} />
      <VisionList {...homePageData} />
      <Quote {...homePageData} />
      <ArticlesHome />
      <MetaHelmet {...homePageData} />
    </>
  );
};

export default HomePage;
