/* For buildings params for strapi see https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest-api.html */

import axios from 'axios';
import qs from 'qs';

const API_URL = process.env.REACT_APP_API_URL;
const TIMEOUT = process.env.REACT_APP_TIMEOUT;

const BuildUrl = (endpoint) => {
  return `${API_URL}${endpoint}`;
};

const StringifyParams = (params) => {
  return qs.stringify(params);
};

const get = async (endpoint, params, abortController) => {
  let config = {
    params: params,
    paramsSerializer: StringifyParams,
    timeout: TIMEOUT,
    signal: abortController.signal,
  };

  return axios.get(BuildUrl(endpoint), config);
};

export { get };
