import { useState } from 'react';
import useTemplateHook from '../TemplateHook';
import { getAboutUsPageData } from '../../services/AboutUsService';
import { parseAboutUsPageData } from '../../handlers/ApiResponseDataHandler';

const useAboutUsContent = () => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [aboutUsPictures, setAboutUsPictures] = useState([]);
  const [meta, setMeta] = useState({});

  const fetchFunction = async (abortController) => {
    return await getAboutUsPageData(abortController);
  };

  const setFunction = (data) => {
    const parsedAboutUsPageData = parseAboutUsPageData(data.data);

    setTitle(parsedAboutUsPageData.title);
    setText(parsedAboutUsPageData.text);
    setAboutUsPictures(parsedAboutUsPageData.aboutUsPictures);
    setMeta(parsedAboutUsPageData.meta);
  };

  const { error, isLoading } = useTemplateHook(
    fetchFunction,
    setFunction,
    true
  );

  return {
    error,
    isLoading,
    title,
    text,
    aboutUsPictures,
    meta,
  };
};

export default useAboutUsContent;
