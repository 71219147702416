import InnerHtml from './InnerHtml';

const Literature = ({ literature }) => {
  return (
    <div className='row bbng-literature-row'>
      <div className='col-12 bbng-literture-border'></div>
      <div className='col-12 col-lg-4 bbng-literature-left'>
        <p>Przypisy</p>
      </div>
      <div className='col-12 col-lg-8 bbng-literature-right'>
        {literature.map((element, index) => {
          return (
            <p key={element.id}>
              <sup>{index + 1} </sup>
              <InnerHtml isInline={true} html={element.element} />
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Literature;
