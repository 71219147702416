import VisionListElement from './VisionListElement';

const VisionList = ({ visionList }) => {
  return (
    <div className='row bbng-vision-row justify-content-between d-none d-lg-flex'>
      {visionList.map((visionListElement) => {
        return (
          <div
            key={visionListElement.id}
            className='col-12 col-xl-4 d-flex flex-column align-items-center'
          >
            <VisionListElement {...visionListElement} />
          </div>
        );
      })}
    </div>
  );
};

export default VisionList;
