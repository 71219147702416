import { useParams, Navigate } from 'react-router';
import useArticleContent from '../hooks/page/ArticlePageHook';
import InnerHtml from '../components/InnerHtml';
import Author from '../components/Author';
import Button from '../components/Button';
import MetaHelmet from '../components/MetaHelmet';
import ArticleContentParts from '../components/ArticleContentParts';
import Literature from '../components/Literature';

const ArticlePage = () => {
  const { slug } = useParams();
  const articleData = useArticleContent(slug);

  if (articleData.error.hasOccured) {
    if (articleData.error.responseStatus === 404) {
      return <Navigate to='/404' />;
    }
    return <p>Przepraszamy, nie możemy teraz wyświetlić tej strony.</p>;
  }

  if (articleData.isLoading) {
    return '';
  }

  const isYoutubeVideo =
    articleData.youtubeVideoId !== null && articleData.youtubeVideoId !== '';
  const isPdfFile =
    articleData.pdfFileUrl !== null && articleData.pdfFileUrl !== '';
  const hasArticleContentParts =
    articleData.atricleContentParts !== null &&
    articleData.atricleContentParts.length > 0;
  const hasFinancingNote =
    articleData.financingNote !== null && articleData.financingNote !== '';
  const hasLiterature =
    articleData.literature !== null && articleData.literature.length > 0;

  return (
    <>
      <div className='row bbng-article-header-row'>
        <div className='col-12'>
          <h1>{articleData.title}</h1>
        </div>
      </div>
      <div className='row bbng-article-authors-row justify-content-between'>
        <Author
          authors={articleData.authors}
          divClassName='col-12 col-lg-6 col-xl-3 mt-xl-4 mt-lg-3 mt-2'
        />
        {isPdfFile ? (
          <div className='col-12 col-lg-6 col-xl-3 mt-xl-4 mt-lg-3 mt-2 position-relative'>
            <Button
              to={articleData.pdfFileUrl}
              text='Pobierz PDF'
              isExternal={true}
              className='bbng-pdf-button'
            />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className='row bbng-article-abstract-row'>
        <div className='d-none d-lg-block col-lg-3 col-xl-3'></div>
        <div className='col-12 col-lg-9 col-xl-8 bbng-article-abstract'>
          <p>{articleData.abstract}</p>
        </div>
        <div className='d-none d-xl-block col-lg-1 col-xl-1'></div>
        <div className='d-none d-lg-block col-lg-3 col-xl-3'></div>
        <div className='col-12 col-lg-9 col-xl-8 bbng-article-info'>
          <p className='mt-xl-3 mt-lg-2 mt-1'>
            <InnerHtml html={articleData.beforeNote} isInline={true} />
          </p>
        </div>
        <div className='d-none d-lg-block col-lg-1 col-xl-1'></div>
      </div>

      {isYoutubeVideo ? (
        <div className='row bbng-article-content-row'>
          <div className='col-12'>
            <div className='ratio ratio-16x9'>
              <iframe
                title={articleData.title}
                src={`https://www.youtube.com/embed/${articleData.youtubeVideoId}?rel=0`}
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      {hasArticleContentParts ? (
        <div className='row bbng-article-content-row'>
          <ArticleContentParts
            articleContentParts={articleData.atricleContentParts}
          />
        </div>
      ) : (
        <InnerHtml
          html={articleData.articleContent}
          className='row bbng-article-content-row'
        />
      )}
      {hasFinancingNote ? (
        <div className='row bbng-article-financing-row'>
          <div className='col-12'>{articleData.financingNote}</div>
        </div>
      ) : (
        ''
      )}
      {hasLiterature ? <Literature literature={articleData.literature} /> : ''}

      <MetaHelmet {...articleData} />
    </>
  );
};

export default ArticlePage;
