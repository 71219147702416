import useArticlesHomeContent from '../hooks/components/ArticlesHomeHook';
import ArticleTrio from './ArticleTrio';
import Button from './Button';

const ArticlesHome = () => {
  const articlesContent = useArticlesHomeContent();
  if (!articlesContent) {
    return;
  }

  if (articlesContent.isLoading) {
    return '';
  }
  if (articlesContent.error.hasOccured) {
    return <p>Przepraszamy, nie możemy teraz wyświetlić nowych materiałów.</p>;
  }

  return (
    <>
      <div className='row bbng-home-article-row'></div>
      <ArticleTrio aritcles={articlesContent.articles} />
      <div className='row mt-5'>
        <div className='col-12 d-lg-none pe-4'>
          <Button to='artykuly' text='Więcej' />
        </div>
        <div className='col-12 d-none d-lg-block pe-4'>
          <Button to='artykuly' text='Więcej' />
        </div>
      </div>
    </>
  );
};

export default ArticlesHome;
