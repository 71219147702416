const tryFetchData = async (getFuction, setErrorfunc, abortController) => {
  let data;
  try {
    data = await getFuction(abortController);
  } catch (error) {
    setErrorfunc(error);
  }

  return data;
};

export { tryFetchData };
