import InnerHtml from './InnerHtml';

const ContactLinkElement = ({ title, text, additionalInfo }) => {
  return (
    <>
      <div className='d-flex flex-column align-items-lg-center align-items-xl-start'>
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <InnerHtml
        html={additionalInfo}
        className='bbng-contact-link-add'
        isInline={true}
      />
    </>
  );
};

export default ContactLinkElement;
