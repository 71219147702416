import useMaterialsPageContent from '../hooks/page/MaterialsPageHook';
import Months from '../components/Months';
import MetaHelmet from '../components/MetaHelmet';
import MaterialsCollapseSection from '../components/MaterialsCollapseSection';

const MaterialsPage = () => {
  const materialsPageData = useMaterialsPageContent();

  if (materialsPageData.error.hasOcurred) {
    return <p>Przepraszamy, nie możemy teraz wyświetlić tej strony.</p>;
  }

  if (materialsPageData.isLoading) {
    return '';
  }

  return (
    <>
      <MaterialsCollapseSection {...materialsPageData.collapseSection} />
      {materialsPageData.month.map((month) => {
        return <Months key={month.id} {...month} />;
      })}
      <MetaHelmet {...materialsPageData} />
    </>
  );
};

export default MaterialsPage;
