import { useState } from 'react';
import useTemplateHook from '../TemplateHook';
import { getArticleDataBySlug } from '../../services/ArticleApiService';
import { parseArticleData } from '../../handlers/ApiResponseDataHandler';

const useArticleContent = (slug) => {
  const [title, setTitle] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [abstract, setAbstrect] = useState('');
  const [beforeNote, setBeforeNote] = useState('');
  const [articleContent, setArticleContent] = useState('');
  const [atricleContentParts, setArticleContentParts] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [youtubeVideoId, setYoutubeVideoId] = useState('');
  const [meta, setMeta] = useState({});
  const [financingNote, setFinancingNote] = useState('');
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const [literature, setLiterature] = useState([]);

  const fetchFunction = async (abortController) => {
    return await getArticleDataBySlug(slug, abortController);
  };

  const setFunction = (data) => {
    const parsedArticleData = parseArticleData(data.data);

    setTitle(parsedArticleData.title);
    setPictureUrl(parsedArticleData.pictureUrl);
    setArticleContent(parsedArticleData.articleContent);
    setArticleContentParts(parsedArticleData.articleContentParts);
    setAbstrect(parsedArticleData.abstract);
    setBeforeNote(parsedArticleData.beforeNote);
    setAuthors(parsedArticleData.authors);
    setYoutubeVideoId(parsedArticleData.youtubeVideoId);
    setMeta(parsedArticleData.meta);
    setFinancingNote(parsedArticleData.financingNote);
    setPdfFileUrl(parsedArticleData.pdfFileUrl);
    setLiterature(parsedArticleData.literature);
  };

  const { error, isLoading } = useTemplateHook(
    fetchFunction,
    setFunction,
    true
  );

  return {
    error,
    isLoading,
    title,
    pictureUrl,
    articleContent,
    atricleContentParts,
    authors,
    abstract,
    beforeNote,
    youtubeVideoId,
    meta,
    financingNote,
    pdfFileUrl,
    literature,
  };
};

export default useArticleContent;
