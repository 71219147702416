import Picture from './Picture';

const AboutUsPicture = (aboutUsPicture) => {
  if (!aboutUsPicture) {
    return;
  }
  return (
    <>
      <div className='d-flex justify-content-lg-center'>
        <Picture
          {...{
            alternativeText: aboutUsPicture.alternativeText,
            url: aboutUsPicture.pictureUrl,
          }}
        />
      </div>
      <p className='d-flex justify-content-lg-center'>
        {aboutUsPicture.description}
      </p>
    </>
  );
};

export default AboutUsPicture;
