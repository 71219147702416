import { NavLink, Link, Outlet } from 'react-router-dom';
import BottomTitle from '../components/BottomTitle';

const hideMenu = () => {
  document.getElementById('navbarSupportedContent').classList.remove('show');
};
const scrollUp = () => {
  window.scrollTo(0, 0);
};

const Layout = () => {
  return (
    <div className='container container-bg'>
      <nav className='navbar navbar-expand-lg text-uppercase d-flex align-items-lg-end align-items-start bg-white sticky-top bbng-nav'>
        <div className='bbng-nav-fill d-lg-none'></div>
        <Link
          to='/'
          onClick={() => {
            hideMenu();
            scrollUp();
          }}
          className='text-decoration-none lh-1 bbng-brand'
        >
          <div className='bbng-logo'></div>
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon navbar-toggler-icon-bbng'></span>
        </button>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav'>
            <li className='nav-item ps-5 d-flex justify-content-end'>
              <NavLink
                to='o-nas'
                className='nav-link pb-0'
                onClick={() => {
                  hideMenu();
                  scrollUp();
                }}
              >
                O nas
              </NavLink>
            </li>
            <li className='nav-item ps-5 d-flex justify-content-end'>
              <NavLink
                to='artykuly'
                className='nav-link pb-0'
                onClick={() => {
                  hideMenu();
                  scrollUp();
                }}
              >
                Materiały
              </NavLink>
            </li>
            <li className='nav-item ps-5 d-flex justify-content-end'>
              <NavLink
                to='linki'
                className='nav-link pb-0'
                onClick={() => {
                  hideMenu();
                  scrollUp();
                }}
              >
                Linki
              </NavLink>
            </li>
            <li className='nav-item ps-5 d-flex justify-content-end'>
              <NavLink
                to='kontakt'
                className='nav-link pb-0'
                onClick={() => {
                  hideMenu();
                  scrollUp();
                }}
              >
                Kontakt
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <Outlet />
      <BottomTitle />
    </div>
  );
};

export default Layout;
