import { getMetaParams, getPictureFields } from '../handlers/CommonHandler';
import { get } from './ApiServiceBase';

const Endpoint = '/main';

const getHomePageData = async (abortController) => {
  let params = {
    populate: {
      header: {
        fields: ['h1', 'h2'],
        populate: {
          picture1: getPictureFields(),
          picture2: getPictureFields(),
          picture3: getPictureFields(),
          picture4: getPictureFields(),
          picture5: getPictureFields(),
          picture6: getPictureFields(),
        },
      },
      visionList: { populate: { element: '*' } },
      quote: {
        populate: {
          picture1: getPictureFields(),
          picture2: getPictureFields(),
          picture3: getPictureFields(),
          picture4: getPictureFields(),
          author: { fields: ['name', 'profession'] },
        },
      },
      aboutUs: {
        fields: ['header', 'text'],
      },
      meta: getMetaParams(),
    },
  };

  return get(Endpoint, params, abortController);
};

export { getHomePageData };
