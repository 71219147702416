const LinkElement = ({ link, displayText, description }) => {
  return (
    <>
      <div className='d-none d-lg-block col-3'></div>
      <div className='col-12 col-lg-9 mb-2'>
        <span className='bbng-link-arrow'></span>
        <a className='bbng-link-element' href={link}>
          {displayText}
        </a>
      </div>
      <div className='d-none d-lg-block col-3'></div>
      <div className='col-12 col-lg-6'>
        <p className='bbng-link-desc'>{description}</p>
      </div>
      <div className='d-none d-lg-block col-3'></div>
    </>
  );
};

export default LinkElement;
