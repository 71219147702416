import { get } from './ApiServiceBase';
import { getMetaParams } from '../handlers/CommonHandler';

const Endpoint = '/contact';

const getContactData = (abortController) => {
  let params = {
    fields: ['title', 'text'],
    populate: { links: { populate: { element: '*' } }, meta: getMetaParams() },
  };

  return get(Endpoint, params, abortController);
};

export { getContactData };
