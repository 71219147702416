import ContactLinkElement from './ContactLinkElement';

const ContactLinks = ({ links }) => {
  return (
    <div className='row bbng-contact-links-row'>
      {links.map((link) => {
        return (
          <div
            key={link.id}
            className='col-12 col-xl-4 d-flex flex-column justify-content-between pe-5'
          >
            <ContactLinkElement {...link} />
          </div>
        );
      })}
    </div>
  );
};

export default ContactLinks;
