import Picture from './Picture';

const MainPageHeader = ({ header }) => {
  return (
    <>
      <div className='row bbng-header-row'>
        <div className='col-3 d-none d-xl-block'>
          <Picture {...header.picture1} shouldAddItile={true} />
        </div>
        <div className='col col-xl-9 d-flex justify-content-end align-items-start fw-bold'>
          <h1 className='fw-bold text-xl-end'>{header.headerH1}</h1>
        </div>
      </div>
      <div className='row bbng-header-row'>
        <div className='order-3 order-md-1 col-xl-3 d-none d-xl-block'>
          <Picture {...header.picture2} shouldAddItile={true} />
        </div>
        <div className='order-2 order-md-2 col-12 col-md-4 col-lg-3 '>
          <Picture {...header.picture3} shouldAddItile={true} />
          <p className='col-12 d-md-none text-end bbng-img-cap'>
            {header.picture3.caption}
          </p>
        </div>
        <div className='d-none order-md-3 d-lg-block d-xl-none d-xxl-block col-lg-1'></div>
        <div className='order-1 order-md-4 col-12 col-md-8 col-lg-8 col-xl-6 col-xxl-5 d-flex justify-content-end align-items-start'>
          <p className='mb-lg-0'>{header.headerH2}</p>
        </div>
      </div>
      <div className='row bbng-header-row'>
        <div className='col-4 col-xl-3 d-none d-lg-block'>
          <Picture {...header.picture4} shouldAddItile={true} />
        </div>
        <div className='col-3 d-none d-xl-block'>
          <Picture {...header.picture5} shouldAddItile={true} />
        </div>
        <div className='col-4 col-xl-3 d-none d-lg-block'>
          <Picture {...header.picture6} shouldAddItile={true} />
        </div>
        <div className='col-4 col-xl-3 d-none d-lg-flex justify-content-end'>
          <div className='frame'></div>
        </div>
      </div>
    </>
  );
};

export default MainPageHeader;
