import InnerHtml from './InnerHtml';

const ArticleCustomPart = ({ text, marginTop, marginBottom, className }) => {
  return (
    <>
      <div className={marginTop}></div>
      <InnerHtml html={text} className={className} />
      <div className={marginBottom}></div>
    </>
  );
};

export default ArticleCustomPart;
