import { useState } from 'react';
import { getArticlesForMaterialsPage } from '../../services/MaterialsApiService';
import { parseArticlesMaterialsData } from '../../handlers/ApiResponseDataHandler';
import useTemplateHook from '../TemplateHook';

const useMaterialsPageContent = () => {
  const [collapseSection, setCollapseSection] = useState({});
  const [month, setMonth] = useState({});
  const [meta, setMeta] = useState({});

  const fetchFunction = async (abortController) => {
    return await getArticlesForMaterialsPage(abortController);
  };

  const setFunction = (data) => {
    const parsedArticlesMaterialsData = parseArticlesMaterialsData(data.data);

    setCollapseSection(parsedArticlesMaterialsData.collapseSection);
    setMonth(parsedArticlesMaterialsData.month);
    setMeta(parsedArticlesMaterialsData.meta);
  };

  const { error, isLoading } = useTemplateHook(
    fetchFunction,
    setFunction,
    true
  );

  return {
    error,
    isLoading,
    collapseSection,
    month,
    meta,
  };
};

export default useMaterialsPageContent;
