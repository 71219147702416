import { Link } from 'react-router-dom';
import Author from './Author';
import Picture from './Picture';

const AddHeader = (title, number, id, slug, hasBorder) => {
  return (
    <Link
      to={`/${slug}`}
      key={`head-${id}`}
      className={`col-12 col-lg-4 bbng-trio-header bbng-trio-header-${number} ${
        hasBorder ? 'bbng-tiro-border' : ''
      }`}
    >
      <h3>{title}</h3>
    </Link>
  );
};

const AddAuthor = (authors, number, id, slug, hasBorder) => {
  let authorsClassName =
    authors.length === 1 ? 'bbng-trio-author' : 'bbng-trio-author-small';

  return (
    <Link
      to={`/${slug}`}
      key={`auth-${id}`}
      className={`col-12 col-lg-4 ${authorsClassName} bbng-trio-author-${number} ${
        hasBorder ? 'bbng-tiro-border' : ''
      }`}
    >
      <Author authors={authors} className='mb-0' />
    </Link>
  );
};

const AddTeaser = (teaser, number, id, slug, hasBorder) => {
  return (
    <Link
      to={`/${slug}`}
      key={`teas-${id}`}
      className={`col-12 col-lg-4 bbng-trio-teaser bbng-trio-teaser-${number} ${
        hasBorder ? 'bbng-tiro-border' : ''
      } d-none d-lg-flex`}
    >
      <p className='mb-0'>{teaser}</p>
    </Link>
  );
};

const AddPicture = (picture, displayAsVideo, number, id, slug, hasBorder) => {
  return (
    <Link
      to={`/${slug}`}
      key={`pic-${id}`}
      className={`col-12 col-lg-4 bbng-trio-img bbng-trio-img-${number} ${
        hasBorder ? 'bbng-tiro-border' : ''
      }`}
    >
      <div className='bottom-0 bbng-image-wrapper'>
        <svg
          width='60'
          height='60'
          viewBox='0 0 60 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={`position-absolute ${displayAsVideo ? '' : 'd-none'}`}
        >
          <path
            d='M30 60C13.4325 60 0 46.5675 0 30C0 13.4325 13.4325 0 30 0C46.5675 0 60 13.4325 60 30C60 46.5675 46.5675 60 30 60ZM22.5 15.0225V44.9775L45 30L22.5 15.0225Z'
            fill='white'
          />
        </svg>
        <Picture {...picture} />
      </div>
    </Link>
  );
};

const AddPictureCaption = (caption, number, id, slug, hasBorder) => {
  return (
    <Link
      to={`/${slug}`}
      key={`picaut-${id}`}
      className={`col-12 col-lg-4 bbng-trio-imgcap bbng-trio-imgcap-${number} ${
        hasBorder ? 'bbng-tiro-border' : ''
      }`}
    >
      {caption != null ? <p>{caption}</p> : ''}
    </Link>
  );
};

const AddOneFiller = (order) => {
  return (
    <div className={`d-none d-lg-flex col-lg-4 bbng-trio-fill-${order}`}></div>
  );
};

const AddTwoFillers = (order) => {
  return (
    <>
      <div
        className={`d-none d-lg-flex col-lg-4 bbng-trio-fill-${order}`}
      ></div>
      <div
        className={`d-none d-lg-flex col-lg-4 bbng-trio-fill-${order + 1}`}
      ></div>
    </>
  );
};

const ArticleTrio = ({ aritcles, hasBorder = false }) => {
  let shouldAddOneFiller = aritcles.length === 2;
  let shouldAddTwoFillers = aritcles.length === 1;

  return (
    <div className='row bbng-article-trio-row d-flex'>
      {aritcles.map((article, index) => {
        return AddHeader(
          article.title,
          index + 1,
          article.id,
          article.slug,
          index !== aritcles.length - 1
        );
      })}
      {shouldAddOneFiller
        ? AddOneFiller(3)
        : shouldAddTwoFillers
        ? AddTwoFillers(2)
        : ''}
      {aritcles.map((article, index) => {
        return AddAuthor(
          article.authors,
          index + 1,
          article.id,
          article.slug,
          index !== aritcles.length - 1
        );
      })}
      {shouldAddOneFiller
        ? AddOneFiller(6)
        : shouldAddTwoFillers
        ? AddTwoFillers(5)
        : ''}
      {aritcles.map((article, index) => {
        return AddTeaser(
          article.teaser,
          index + 1,
          article.id,
          article.slug,
          index !== aritcles.length - 1
        );
      })}
      {shouldAddOneFiller
        ? AddOneFiller(9)
        : shouldAddTwoFillers
        ? AddTwoFillers(8)
        : ''}
      {aritcles.map((article, index) => {
        return AddPicture(
          article.picture,
          article.displayAsVideo,
          index + 1,
          article.id,
          article.slug,
          index !== aritcles.length - 1
        );
      })}
      {shouldAddOneFiller
        ? AddOneFiller(12)
        : shouldAddTwoFillers
        ? AddTwoFillers(11)
        : ''}
      {aritcles.map((article, index) => {
        return AddPictureCaption(
          article.picture.caption,
          index + 1,
          article.id,
          article.slug,
          index !== aritcles.length - 1
        );
      })}
      {shouldAddOneFiller
        ? AddOneFiller(15)
        : shouldAddTwoFillers
        ? AddTwoFillers(14)
        : ''}

      {hasBorder ? (
        <div className='col-12 d-flex d-lg-none bbng-tiro-bottom-border'> </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ArticleTrio;
