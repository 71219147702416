import LinkElement from './LinkElement';
import { useState } from 'react';

const Links = ({ links, description, title, id }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!links || !description || !title) return '';
  return (
    <>
      <div className='row bbng-links-row'>
        <div className='col-10 col-lg-3 order-lg-1'>
          <h1>{title}</h1>
        </div>
        <div className='col-2 col-lg-3 order-lg-3 d-flex align-items-end align-items-lg-start'>
          <a
            className={
              isCollapsed
                ? 'bbng-collapse-button-down'
                : 'bbng-collapse-button-up'
            }
            data-bs-toggle='collapse'
            href={`#collapsedText1-${id}`}
            role='button'
            aria-expanded='false'
            aria-controls='collapsedText'
            onClick={handleCollapse}
          ></a>
        </div>
        <div className='col-12 col-lg-6 order-lg-2'>
          <p className='bbng-links-desc'>{description}</p>
        </div>
      </div>
      <div
        id={`collapsedText1-${id}`}
        className='row bbng-collapsed-links collapse'
      >
        <div className='mt-4'></div>
        {links.map((link) => {
          return <LinkElement {...link} key={link.id} />;
        })}
      </div>
    </>
  );
};

export default Links;
