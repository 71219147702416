import Picture from './Picture';
import Author from './Author';

const Quote = ({ quote }) => {
  return (
    <>
      <div className='row bbng-quote-pictures bbng-quote-row d-none d-lg-flex'>
        <div className='col-12'>
          <Picture
            {...quote.picture1}
            shouldAddItile={true}
            className='left-top-img'
          ></Picture>
          <Picture
            {...quote.picture2}
            shouldAddItile={true}
            className='right-top-img'
          ></Picture>
          <Picture
            {...quote.picture3}
            shouldAddItile={true}
            className='left-bottom-img'
          ></Picture>
          <Picture
            {...quote.picture4}
            shouldAddItile={true}
            className='right-bottom-img'
          ></Picture>
        </div>
      </div>
      <div className='row bbng-quote-row d-none d-lg-block'>
        <div className='col-12 text-center'>
          <h3 className='bbng-quote-text'>"{quote.quoteText}"</h3>
        </div>
      </div>
      <div className='row d-none d-lg-block'>
        <div className='col-12 d-flex flex-row-reverse pt-4'>
          <Author className='text-end' authors={[quote.author]} />
        </div>
      </div>
    </>
  );
};

export default Quote;
