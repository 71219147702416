const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getAttributes = (dataObject) => {
  return dataObject !== null && dataObject.data !== null
    ? dataObject.data.attributes
    : null;
};

const getMarginClass = (marginSize) => {
  switch (marginSize) {
    case 'XS':
      return 'mt-1';
    case 'S':
      return 'mt-2';
    case 'M':
      return 'mt-3';
    case 'L':
      return 'mt-4';
    case 'XL':
      return 'mt-5';
    default:
      return null;
  }
};

const buildAssetUrl = (assetUrl) => {
  return assetUrl !== null ? `${SERVER_URL}${assetUrl}` : null;
};

const buildPictureObject = (imageAttributes) => {
  if (imageAttributes === null) return null;

  return {
    url: buildAssetUrl(imageAttributes.url),
    alternativeText: imageAttributes.alternativeText,
    caption:
      imageAttributes.caption !== imageAttributes.name
        ? imageAttributes.caption
        : null,
  };
};

const buildAuthorObject = (authorAttributes) => {
  if (authorAttributes === null) return null;

  return {
    name: authorAttributes.name,
    profession: authorAttributes.profession,
  };
};

const buildMetaObject = (meta, additionalMeta) => {
  if (meta === null) return null;

  return {
    title: meta.title,
    metaDescription: meta.metaDescription,
    ...additionalMeta,
  };
};

const buildArticleContentPartObject = (articleContentPart) => {
  return {
    header: articleContentPart.header,
    text: articleContentPart.text,
    picture: buildPictureObject(getAttributes(articleContentPart.picture)),
    pictureDescription: articleContentPart.pictureDescription,
    displayAs: articleContentPart.displayAs,
    marginTop: getMarginClass(articleContentPart.marginTop),
    marginBottom: getMarginClass(articleContentPart.marginBottom),
    className: articleContentPart.className,
  };
};

const buildAuthorsList = (authors) => {
  return authors.data.map((author) => {
    return buildAuthorObject({ ...author.attributes });
  });
};

const buildArticleContentParts = (articleContentParts) => {
  return articleContentParts.map((articleContentPart) => {
    return buildArticleContentPartObject(articleContentPart);
  });
};

const mapToArticleObject = (article) => {
  return article !== null
    ? {
        id: article.id,
        title: article.attributes.title,
        teaser: article.attributes.teaser,
        slug: article.attributes.slug,
        displayAsVideo: article.attributes.displayAsVideo,
        authors: buildAuthorsList(article.attributes.authors),
        picture: buildPictureObject(getAttributes(article.attributes.picture)),
      }
    : null;
};

const mapToMonthObject = (month) => {
  return month !== null
    ? {
        id: month.id,
        title: month.title,
        articles: month.articles.data.map((article) => {
          return mapToArticleObject(article);
        }),
      }
    : null;
};

const mapToAboutUsPicture = (aboutUsPicture) => {
  if (aboutUsPicture === null) return null;

  let pictureAttributes = getAttributes(aboutUsPicture.picture);

  return {
    id: aboutUsPicture.id,
    description: aboutUsPicture.description,
    pictureUrl: buildAssetUrl(pictureAttributes.url),
    pictureAlternativeText: pictureAttributes.alternativeText,
  };
};

const parseHomePageData = (apiResponseData) => {
  let mainAttributes = getAttributes(apiResponseData);
  let headerPicture1Attributes = getAttributes(mainAttributes.header.picture1);
  let headerPicture2Attributes = getAttributes(mainAttributes.header.picture2);
  let headerPicture3Attributes = getAttributes(mainAttributes.header.picture3);
  let headerPicture4Attributes = getAttributes(mainAttributes.header.picture4);
  let headerPicture5Attributes = getAttributes(mainAttributes.header.picture5);
  let headerPicture6Attributes = getAttributes(mainAttributes.header.picture6);
  let quotePicture1Attributes = getAttributes(mainAttributes.quote.picture1);
  let quotePicture2Attributes = getAttributes(mainAttributes.quote.picture2);
  let quotePicture3Attributes = getAttributes(mainAttributes.quote.picture3);
  let quotePicture4Attributes = getAttributes(mainAttributes.quote.picture4);
  let quoteAuthorAttributes = getAttributes(mainAttributes.quote.author);

  return {
    header: {
      headerH1: mainAttributes.header.h1,
      headerH2: mainAttributes.header.h2,
      picture1: buildPictureObject(headerPicture1Attributes),
      picture2: buildPictureObject(headerPicture2Attributes),
      picture3: buildPictureObject(headerPicture3Attributes),
      picture4: buildPictureObject(headerPicture4Attributes),
      picture5: buildPictureObject(headerPicture5Attributes),
      picture6: buildPictureObject(headerPicture6Attributes),
    },
    aboutUs: mainAttributes.aboutUs,
    visionList: mainAttributes.visionList.element,
    quote: {
      quoteText: mainAttributes.quote.text,
      picture1: buildPictureObject(quotePicture1Attributes),
      picture2: buildPictureObject(quotePicture2Attributes),
      picture3: buildPictureObject(quotePicture3Attributes),
      picture4: buildPictureObject(quotePicture4Attributes),
      author: buildAuthorObject(quoteAuthorAttributes),
    },
    meta: buildMetaObject(mainAttributes.meta),
  };
};

const parseContactPageData = (apiResponseData) => {
  let mainAttributes = getAttributes(apiResponseData);

  return {
    title: mainAttributes.title,
    text: mainAttributes.text,
    links: mainAttributes.links ? mainAttributes.links.element : null,
    meta: buildMetaObject(mainAttributes.meta),
  };
};

const parseArticlesHomeData = (apiResponseData) => {
  return apiResponseData.data.map((article) => {
    return mapToArticleObject(article);
  });
};

const parseArticlesMaterialsData = (apiResponseData) => {
  let mainAttributes = getAttributes(apiResponseData);
  return {
    collapseSection: mainAttributes.collapseSection,
    month: mainAttributes.month.map((month) => {
      return mapToMonthObject(month);
    }),
    meta: buildMetaObject(mainAttributes.meta),
  };
};

const parseAboutUsPageData = (apiResponseData) => {
  let mainAttributes = getAttributes(apiResponseData);

  return {
    title: mainAttributes.title,
    text: mainAttributes.text,
    aboutUsPictures: mainAttributes.aboutUsPicture.map((aboutUsPicture) => {
      return mapToAboutUsPicture(aboutUsPicture);
    }),
    meta: buildMetaObject(mainAttributes.meta),
  };
};

const parseArticleData = (apiResponseData) => {
  let mainAttributes = getAttributes(apiResponseData);
  let pictureAtributes = getAttributes(mainAttributes.picture);
  let pdfAttributes = getAttributes(mainAttributes.pdfFile);

  return {
    title: mainAttributes.title,
    abstract: mainAttributes.abstract,
    beforeNote: mainAttributes.beforeNote,
    articleContent: mainAttributes.articleContent,
    articleContentParts: buildArticleContentParts(
      mainAttributes.articleContentParts
    ),
    authors: buildAuthorsList(mainAttributes.authors),
    youtubeVideoId: mainAttributes.video
      ? mainAttributes.video.youtubeVideoId
      : null,
    meta: buildMetaObject(mainAttributes.meta, {
      picture: buildPictureObject(pictureAtributes),
    }),
    financingNote: mainAttributes.financingNote,
    pdfFileUrl: pdfAttributes ? buildAssetUrl(pdfAttributes.url) : null,
    literature: mainAttributes.literature,
  };
};

const parseLinkPageData = (apiResponseData) => {
  let mainAttributes = getAttributes(apiResponseData);

  return {
    reports: mainAttributes.reports ? mainAttributes.reports : null,
    research: mainAttributes.research ? mainAttributes.research : null,
    press: mainAttributes.press ? mainAttributes.press : null,
    meta: buildMetaObject(mainAttributes.meta),
  };
};

export {
  parseHomePageData,
  parseArticlesHomeData,
  parseArticlesMaterialsData,
  parseArticleData,
  parseAboutUsPageData,
  parseContactPageData,
  parseLinkPageData,
};
