import { useState, useEffect } from 'react';
import { tryFetchData } from '../handlers/ApiFetchDataHandler';

const useTemplateHook = (
  fetchFunctionAsync,
  setFunction,
  isPageHook = false
) => {
  const [error, setError] = useState({ hasOccured: false });
  const [isLoading, setIsLoading] = useState(true);

  const handleError = (error) => {
    setError({
      hasOccured: true,
      responseStatus:
        error.response && error.response.status ? error.response.status : '',
    });
    setIsLoading(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const data = await tryFetchData(
        fetchFunctionAsync,
        handleError,
        abortController
      );

      setFunction(data);
      setIsLoading(false);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        });
      }, 100);
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  return { error, isLoading };
};

export default useTemplateHook;
