import { useEffect } from 'react';
import Helmet from 'react-helmet';

const MetaHelmet = ({ meta }) => {
  useEffect(() => {
    window.prerenderReady = true;
  }, []);

  if (meta === null || meta === undefined)
    return (
      <Helmet>
        <title></title>
        <meta name='description' content='' />
      </Helmet>
    );

  const title = meta.title ? meta.title : 'BBnG';
  const description = meta.metaDescription
    ? meta.metaDescription
    : 'Badaczki i Badacze na Granicy';
  const imageUrl =
    meta.picture && meta.picture.url
      ? meta.picture.url
      : `https://${window.location.hostname}/pictures/og_default.png`;
  const imageAlt =
    meta.picture && meta.picture.alternativeText
      ? meta.picture.alternativeText
      : 'Badaczki i Badacze na Granicy';

  return (
    <Helmet>
      <title>{title}</title>
      <meta property='og:title' content={title} />
      <meta property='twitter:title' content={title} />
      <meta name='description' content={description} />
      <meta property='og:description' content={description} />
      <meta property='twitter:description' content={description} />
      <meta property='og:image' content={imageUrl} />
      <meta property='twitter:image' content={imageUrl} />
      <meta property='og:image:alt' content={imageAlt} />
      <meta property='twitter:image:alt' content={imageAlt} />
    </Helmet>
  );
};

export default MetaHelmet;
