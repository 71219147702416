import { useState } from 'react';
import useTemplateHook from '../TemplateHook';
import { getLinksData } from '../../services/LinksApiService';
import { parseLinkPageData } from '../../handlers/ApiResponseDataHandler';

const useLinkPageContent = () => {
  const [reports, setReports] = useState({});
  const [research, setResearch] = useState({});
  const [press, setPress] = useState({});
  const [meta, setMeta] = useState({});

  const fetchFunction = async (abortController) => {
    return await getLinksData(abortController);
  };

  const setFunction = (data) => {
    const parsedLinkPageData = parseLinkPageData(data.data);

    setReports(parsedLinkPageData.reports);
    setResearch(parsedLinkPageData.research);
    setPress(parsedLinkPageData.press);
    setMeta(parsedLinkPageData.meta);
  };

  const { error, isLoading } = useTemplateHook(
    fetchFunction,
    setFunction,
    true
  );

  return {
    error,
    reports,
    research,
    press,
    isLoading,
    meta,
  };
};

export default useLinkPageContent;
