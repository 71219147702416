import { Link } from 'react-router-dom';
import MetaHelmet from '../components/MetaHelmet';

const NotFoundPage = () => {
  return (
    <>
      <div className='container container-bg bbng-404'>
        <div className='row'>
          <div className='col-12 col-lg-8 d-flex flex-column px-1'>
            <div className='bbng-404-nav-row d-flex align-items-end'>
              <Link
                to='/'
                className='text-decoration-none bbng-brand text-uppercase lh-1'
              >
                <span className='fw-bold text-dark'>
                  Badaczki <br />i Badacze
                </span>
                <br /> na granicy
              </Link>
            </div>
            <div className='d-flex flex-column align-items-center'>
              <h1>404</h1>
              <h2>Ta strona nie istnieje</h2>
            </div>
          </div>
          <div className='d-none d-lg-flex col-lg-4'>
            <img src='/pictures/404.png' alt='404 image' />
          </div>
        </div>
      </div>
      <MetaHelmet {...{ meta: { title: '404' } }} />
    </>
  );
};

export default NotFoundPage;
