import { get } from './ApiServiceBase';
import {
  getArticleThubnailsParams,
  getMetaParams,
} from '../handlers/CommonHandler';

const Endpoint = '/materials-page';

const getArticlesForMaterialsPage = async (abortController) => {
  let articleThumbnailsParams = getArticleThubnailsParams();
  articleThumbnailsParams.sort = ['createdAt:desc'];
  let params = {
    populate: {
      collapseSection: { fields: ['title', 'teaser', 'text'] },
      month: {
        fields: ['title'],
        populate: { articles: articleThumbnailsParams },
      },
      meta: getMetaParams(),
    },
  };

  return get(Endpoint, params, abortController);
};

export { getArticlesForMaterialsPage };
