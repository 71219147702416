import MonthArticles from './MonthArticles';

const Months = ({ title, articles }) => {
  return (
    <>
      <div className='row bbng-month-row d-flex justify-content-between'>
        <div className='d-none d-lg-block col-lg-5 position-relative bbng-solid'></div>
        <div className='d-flex col-12 col-lg-2 flex-column align-items-end align-items-lg-center'>
          {title}
        </div>
        <div className='d-none d-lg-block col-lg-5 position-relative bbng-solid'></div>
      </div>
      <MonthArticles articles={articles} />
    </>
  );
};

export default Months;
