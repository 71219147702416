const Picture = (pictureData) => {
  let className = pictureData.className ? pictureData.className : '';
  let shouldAddItile = pictureData.shouldAddItile ? true : false;
  return (
    <img
      src={pictureData.url}
      alt={pictureData.alternativeText}
      className={className}
      title={shouldAddItile ? pictureData.caption : null}
    />
  );
};

export default Picture;
