import { useState } from 'react';
import useTemplateHook from '../TemplateHook';
import { getContactData } from '../../services/ContactApiService';
import { parseContactPageData } from '../../handlers/ApiResponseDataHandler';

const useContactPageContent = () => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState({});

  const fetchFunction = async (abortController) => {
    return await getContactData(abortController);
  };

  const setFunction = (data) => {
    const parsedContactPageData = parseContactPageData(data.data);

    setTitle(parsedContactPageData.title);
    setText(parsedContactPageData.text);
    setLinks(parsedContactPageData.links);
    setMeta(parsedContactPageData.meta);
  };

  const { error, isLoading } = useTemplateHook(
    fetchFunction,
    setFunction,
    true
  );

  return {
    error,
    isLoading,
    title,
    text,
    links,
    meta,
  };
};

export default useContactPageContent;
