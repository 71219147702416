import Picture from './Picture';

const ArticleImagePart = ({ picture, pictureDescription }) => {
  return (
    <div className='col-12 bbng-article-image-col'>
      <Picture {...picture} shouldAddItile={true} />
      {pictureDescription !== null && pictureDescription !== '' ? (
        <p>{pictureDescription}</p>
      ) : (
        ''
      )}
    </div>
  );
};

export default ArticleImagePart;
