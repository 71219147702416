const VisionListElement = ({ title, text }) => {
  return (
    <>
      <h3>{title}</h3>
      <p className='text-center'>{text}</p>
    </>
  );
};

export default VisionListElement;
