import useContactPageContent from '../hooks/page/ContactPageHook';
import ContactLinks from '../components/ContactLinks';
import MetaHelmet from '../components/MetaHelmet';

const ContactPage = () => {
  const contactPageData = useContactPageContent();

  if (contactPageData.error.hasOccured) {
    return <p>Przepraszamy, nie możemy teraz wyświetlić tej strony.</p>;
  }

  if (contactPageData.isLoading) {
    return <p>loading content</p>;
  }

  return (
    <>
      <ContactLinks {...contactPageData} />
      <MetaHelmet {...contactPageData} />
    </>
  );
};

export default ContactPage;
