import InnerHtml from './InnerHtml';

const ArticleParagraphPart = ({ header, text }) => {
  return (
    <>
      <div className='col-12 col-lg-2 bbng-article-content-left'>
        <p>{header}</p>
      </div>
      <div className='d-none d-lg-block col-lg-1'></div>
      <InnerHtml className={'col-12 col-lg-9'} html={text} />
    </>
  );
};

export default ArticleParagraphPart;
