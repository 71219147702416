import useAboutUsContent from '../hooks/page/AboutUsHook';
import AboutUsPicture from '../components/AboutUsPicture';
import MetaHelmet from '../components/MetaHelmet';
import InnerHtml from '../components/InnerHtml';

const AboutUsPage = () => {
  const aboutUsContent = useAboutUsContent();

  if (aboutUsContent.error.hasOccured) {
    return <p>Przepraszamy, nie możemy teraz wyświetlić tej strony.</p>;
  }

  if (aboutUsContent.isLoading) {
    return '';
  }

  return (
    <>
      <div className='row bbng-about-us-row'>
        <div className='col-12 col-lg-4'>
          <h1>{aboutUsContent.title}</h1>
        </div>
        <InnerHtml
          html={aboutUsContent.text}
          className='col-12 col-lg-7 bbng-about-us-text'
        />
        <div className='d-none d-lg-block col-lg-1'></div>
        <div className='col-12 col-lg-4 bbng-about-us-picture'>
          <AboutUsPicture {...aboutUsContent.aboutUsPictures[0]} />
        </div>
        <div className='col-12 col-lg-4 bbng-about-us-picture'>
          <AboutUsPicture {...aboutUsContent.aboutUsPictures[1]} />
        </div>
        <div className='col-12 col-lg-4 bbng-about-us-picture'>
          <AboutUsPicture {...aboutUsContent.aboutUsPictures[2]} />
        </div>
        <div className='d-none d-lg-block col-lg-2'></div>
        <div className='col-12 col-lg-4 bbng-about-us-picture'>
          <AboutUsPicture {...aboutUsContent.aboutUsPictures[3]} />
        </div>
        <div className='col-12 col-lg-4 bbng-about-us-picture'>
          <AboutUsPicture {...aboutUsContent.aboutUsPictures[4]} />
        </div>
        <div className='d-none d-lg-block col-lg-2'></div>
      </div>

      <MetaHelmet {...aboutUsContent} />
    </>
  );
};

export default AboutUsPage;
