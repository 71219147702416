import InnerHtml from './InnerHtml';
import { useState } from 'react';

const MaterialsCollapseSection = (collapseSection) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (collapseSection === null) return '';

  return (
    <>
      <div className='row bbng-materials-teaser'>
        <div className='col-12 col-lg-4'>
          <h1>{collapseSection.title}</h1>
        </div>
        <InnerHtml className='col-12 col-lg-6' html={collapseSection.teaser} />
        <div className='d-none d-lg-block col-lg-2'></div>
      </div>
      <div id='collapsedText' className='row bbng-materials-collapse collapse'>
        <div className='col-12 col-lg-4'></div>
        <InnerHtml className='col-12 col-lg-6 ' html={collapseSection.text} />
        <div className='d-none d-lg-block col-lg-2'></div>
      </div>
      <div className='row'>
        <div className='d-none d-lg-block col-lg-4'></div>
        <div className='col-12 col-lg-6'>
          <a
            className={
              isCollapsed
                ? 'bbng-collapse-button-down'
                : 'bbng-collapse-button-up'
            }
            data-bs-toggle='collapse'
            href='#collapsedText'
            role='button'
            aria-expanded='false'
            aria-controls='collapsedText'
            onClick={handleCollapse}
          ></a>
        </div>
        <div className='d-none d-lg-block col-lg-2'></div>
      </div>
    </>
  );
};

export default MaterialsCollapseSection;
