import { useLocation } from 'react-router-dom';

const BottomTitle = () => {
  const location = useLocation();
  return (
    <>
      {location.pathname === '/' ? (
        <div className='row bbng-thanks-row'>
          <div className='col-12 col-lg-3 mb-2'>
            <h3>Bardzo dziękujemy:</h3>
          </div>
          <div className='d-none d-lg-block col-lg-1'></div>
          <div className='col-12 col-lg-8'>
            <p>
              Mateuszowi Żebrowskiemu - za stworzenie niniejszej strony
              internetowej,
              <br />
              Lenie Polyn - za projekt graficzny danej strony, <br />
              Wiktorii Nataszy Konwent - za zrobienie logo i materiałów
              promocyjnych dla BBnG, <br />
              Maciejowi Moskwie, Ance Kolber - za pozwolenie na wykorzystanie na
              stronie BBnG zdjęć ich autorstwa.
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className='row bbng-bottom-row'>
        <div className='d-none d-lg-block col-lg-1 col-xl-1 col-xxl-2'></div>
        <div className='col-12 col-lg-9 col-xxl-7 d-flex align-items-end flex-column'>
          <h1>Badaczki i badacze</h1>
          <h2>na granicy</h2>
        </div>
        <div className='d-none d-lg-block col-lg-2 col-xxl-3'></div>
      </div>
      <div className='row bbng-end-row'>
        <div className='d-none d-lg-block col-lg-2 col-xl-3 col-xxl-3'></div>
        <div className='col-12 col-lg-2 col-xl-2 col-xxl-4 ps-1'>
          <span>
            <a href='https://www.facebook.com/BadaczeiBadaczkiNaGranicy'>
              facebook.com
            </a>
          </span>
        </div>
        <div className='col-12 col-lg-6 col-xl-5 col-xxl-2 d-flex align-items-lg-end flex-column ps-1'>
          <span>badaczenagranicy@gmail.com</span>
        </div>
        <div className='d-none d-lg-block col-lg-2 col-xl-2 col-xxl-3'></div>
      </div>
      {location.pathname === '/' ? (
        <>
          <div className='row bbng-bottom-logo-row'>
            <div className='col-4 col-lg-2 bbng-batory'></div>
            <div className='col-8 col-lg-2 bbng-egala'></div>
          </div>
          <div className='row bbng-bottom-financing-row'>
            <div className='d-none d-lg-block col-lg-8'></div>
            <div className='col-12 col-lg-7 d-flex align-items-end mb-5 mb-lg-0'>
              <p className='align-bottom'>
                Wykonanie strony zostało sfinansowane z grantu Fundacji Batorego
              </p>
            </div>
            <div className='col-12 col-lg-5'>
              <p className='text-lg-end'>Design by Polyn Olena</p>
              <p className='text-lg-end'>
                All rights reserved© 2022 Badaczki i Badacze na Granicy
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row bbng-bottom-financing-row'>
            <div className='d-none d-lg-block col-lg-8'></div>
            <div className='col-12 col-lg-7 d-flex align-items-end mb-5 mb-lg-0'></div>
            <div className='col-12 col-lg-5'>
              <p className='text-lg-end'>Design by Polyn Olena</p>
              <p className='text-lg-end'>
                All rights reserved© 2022 Badaczki i Badacze na Granicy
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BottomTitle;
