import { get } from './ApiServiceBase';
import { getMetaParams } from '../handlers/CommonHandler';

const Endpoint = '/link';

const getLinksData = (abortController) => {
  let params = {
    populate: {
      reports: { populate: { links: '*' } },
      research: { populate: { links: '*' } },
      press: { populate: { links: '*' } },
      meta: getMetaParams(),
    },
  };

  return get(Endpoint, params, abortController);
};

export { getLinksData };
