import { useState } from 'react';
import { getHomePageData } from '../../services/HomePageApiService';
import { parseHomePageData } from '../../handlers/ApiResponseDataHandler';
import useTemplateHook from '../TemplateHook';

const useHomePageContent = () => {
  const [header, setHeader] = useState({});
  const [aboutUs, setAboutUs] = useState({});
  const [visionList, setVisionList] = useState([]);
  const [quote, setQuote] = useState({});
  const [meta, setMeta] = useState({});

  const fetchFunction = async (abortController) => {
    return await getHomePageData(abortController);
  };

  const setFunction = (data) => {
    const parsedMainPageData = parseHomePageData(data.data);

    setHeader(parsedMainPageData.header);
    setAboutUs(parsedMainPageData.aboutUs);
    setVisionList(parsedMainPageData.visionList);
    setQuote(parsedMainPageData.quote);
    setMeta(parsedMainPageData.meta);
  };

  const { error, isLoading } = useTemplateHook(
    fetchFunction,
    setFunction,
    true
  );

  return {
    error,
    isLoading,
    header,
    aboutUs,
    visionList,
    quote,
    meta,
  };
};

export default useHomePageContent;
