const getPictureFields = () => {
  return { fields: ['url', 'alternativeText', 'name', 'caption'] };
};

const getArticleThubnailsParams = () => {
  return {
    fields: ['title', 'teaser', 'slug', 'displayAsVideo'],
    populate: {
      authors: { fields: ['name', 'profession'] },
      picture: getPictureFields(),
      video: { fields: ['youtubeVideoId'] },
    },
  };
};

const getMetaParams = () => {
  return {
    fields: ['title', 'metaDescription'],
  };
};

const getTrioArray = (array) => {
  let trioArray = [];
  let lastTrioIndex = Math.min(2, array.length - 1);

  for (let i = 0; i < array.length; i += 3) {
    trioArray.push(array.slice(i, lastTrioIndex + 1));
    lastTrioIndex = Math.min(lastTrioIndex + 3, array.length - 1);
  }

  return trioArray;
};

export {
  getPictureFields,
  getArticleThubnailsParams,
  getMetaParams,
  getTrioArray,
};
