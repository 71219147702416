import DOMPurify from 'dompurify';

const InnerHtml = ({ html, className, isInline = false, id = null }) => {
  if (isInline) {
    return (
      <span
        className={className}
        id={id}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      ></span>
    );
  }

  return (
    <div
      className={className}
      id={id}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html, {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
        }),
      }}
    ></div>
  );
};

export default InnerHtml;
