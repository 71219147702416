import { get } from './ApiServiceBase';
import { getMetaParams, getPictureFields } from '../handlers/CommonHandler';

const Endpoint = '/about-us';

const getAboutUsPageData = async (abortController) => {
  let params = {
    fields: ['title', 'text'],
    populate: {
      aboutUsPicture: {
        populate: { picture: getPictureFields() },
      },
      meta: getMetaParams(),
    },
  };

  return get(Endpoint, params, abortController);
};

export { getAboutUsPageData };
