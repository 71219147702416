import ArticleTrio from './ArticleTrio';
import { getTrioArray } from '../handlers/CommonHandler';

const MonthArticles = ({ articles }) => {
  if (articles === null || articles.length === 0) return;

  let articlesTrioArray = getTrioArray(articles);

  return articlesTrioArray.map((trio, index) => {
    return (
      <>
        <div className='row bbng-materials-article-row'></div>
        <ArticleTrio
          key={index}
          aritcles={trio}
          hasBorder={index !== articlesTrioArray.length - 1}
        />
      </>
    );
  });
};

export default MonthArticles;
