import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import MaterialsPage from './pages/MaterialsPage';
import ContactPage from './pages/ContactPage';
import ArticlePage from './pages/ArticlePage';
import NotFoundPage from './pages/NotFoundPage';
import LinksPage from './pages/LinksPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/404' element={<NotFoundPage />} />
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='o-nas' element={<AboutUsPage />} />
          <Route path='artykuly' element={<MaterialsPage />} />
          <Route path='linki' element={<LinksPage />} />
          <Route path='kontakt' element={<ContactPage />} />
          <Route path=':slug' element={<ArticlePage />} />
        </Route>
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
