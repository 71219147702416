import { useState } from 'react';
import { parseArticlesHomeData } from '../../handlers/ApiResponseDataHandler';
import { getHomePageArticlesData } from '../../services/ArticleApiService';
import useTemplateHook from '../TemplateHook';

const useArticlesHomeContent = () => {
  const [articles, setArticles] = useState([]);

  const setFunction = (data) => {
    const parsedArticlesData = parseArticlesHomeData(data.data);

    setArticles(parsedArticlesData);
  };

  const { error, isLoading } = useTemplateHook(
    getHomePageArticlesData,
    setFunction
  );

  return { error, isLoading, articles };
};

export default useArticlesHomeContent;
