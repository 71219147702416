const Author = ({ authors, className, divClassName }) => {
  if (authors === null || authors.length === 0) return '';
  if (authors.length === 1) {
    return (
      <div className={divClassName ? divClassName : ''}>
        <p className={`bbng-author ${className ? className : ''}`}>
          {authors[0].name}
          <br />
          {authors[0].profession}
        </p>
      </div>
    );
  }
  return (
    <div className={divClassName ? divClassName : ''}>
      <p className={`bbng-author-small ${className ? className : ''}`}>
        {authors[0].name}
        <br />
        {authors[0].profession}
        <br />
        {authors[1].name}
        <br />
        {authors[1].profession}
      </p>
    </div>
  );
};

export default Author;
