import { get } from './ApiServiceBase';
import {
  getArticleThubnailsParams,
  getMetaParams,
  getPictureFields,
} from '../handlers/CommonHandler';

const Endpoint = '/articles';

const getHomePageArticlesData = async (abortController) => {
  let params = getArticleThubnailsParams();
  params.sort = ['createdAt:desc'];
  params.pagination = { pageSize: 3 };

  return get(Endpoint, params, abortController);
};

const getArticleDataBySlug = async (slug, abortController) => {
  let params = {
    fields: [
      'title',
      'abstract',
      'beforeNote',
      'articleContent',
      'financingNote',
    ],
    populate: {
      articleContentParts: {
        fields: [
          'header',
          'text',
          'pictureDescription',
          'displayAs',
          'marginTop',
          'marginBottom',
          'className',
        ],
        populate: {
          picture: getPictureFields(),
        },
      },
      authors: { fields: ['name', 'profession'] },
      picture: getPictureFields(),
      video: { fields: ['youtubeVideoId'] },
      pdfFile: { fields: ['url'] },
      literature: { fields: ['element'] },
      meta: getMetaParams(),
    },
  };

  return get(`${Endpoint}/${slug}`, params, abortController);
};

export { getHomePageArticlesData, getArticleDataBySlug };
