import { DisplayAs } from '../const/ArticleConsts';
import ArticleParagraphPart from './ArticleParagraphPart';
import ArticleImagePart from './ArticleImagePart';
import ArticleCustomPart from './ArticleCustomPart';

const ArticleContentParts = ({ articleContentParts }) => {
  return articleContentParts.map((part) => {
    if (part.displayAs === DisplayAs.paragraph) {
      return <ArticleParagraphPart {...part} key={part.id} />;
    }
    if (part.displayAs === DisplayAs.picture) {
      return <ArticleImagePart {...part} key={part.id} />;
    }
    if (part.displayAs === DisplayAs.custom) {
      return <ArticleCustomPart {...part} key={part.id} />;
    }
    return '';
  });
};

export default ArticleContentParts;
