import InnerHtml from './InnerHtml';
import Button from './Button';

const AboutUsHome = ({ aboutUs }) => {
  return (
    <div className='row bbng-home-about-row'>
      <div className='col-12 col-lg-6 pe-4 d-flex flex-column justify-content-between'>
        <h3>{aboutUs.header}</h3>
        <div className='col-12 d-none d-lg-block'>
          <Button to='o-nas' text='Czytaj więcej' />
        </div>
      </div>
      <div className='col-12 col-lg-6 ps-lg-4 pt-4 pt-lg-0'>
        <InnerHtml html={aboutUs.text} />
      </div>
      <div className='col-12 d-block d-lg-none'>
        <Button className='mt-5' to='o-nas' text='Czytaj więcej' />
      </div>
    </div>
  );
};

export default AboutUsHome;
